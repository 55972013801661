import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./press.css"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pressCover: file(relativePath: { eq: "press-cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 543) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      press1: file(relativePath: { eq: "press1.png" }) {
        childImageSharp {
          fluid(maxWidth: 181) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      press2: file(relativePath: { eq: "press2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      press3: file(relativePath: { eq: "press3.png" }) {
        childImageSharp {
          fluid(maxWidth: 158) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      press4: file(relativePath: { eq: "press4.png" }) {
        childImageSharp {
          fluid(maxWidth: 286) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      press5: file(relativePath: { eq: "press5.png" }) {
        childImageSharp {
          fluid(maxWidth: 222) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      press6: file(relativePath: { eq: "gofg-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 222) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout page="press">
      <SEO title="Press" description="Interested in sharing our story? Reach out here."/>
      <div className="flex-grid mobile-wrap">
        <div className="press-left press-padder" />
        <div className="press-big-text press-left">
          <div className="vertical-center">
            “This revolutionary service is changing the landscape of an industry
            tainted by exploitation and giving models the autonomy to curate
            their own social lives while promoting the businesses via social
            media…”
            <div className="share-out-story only-desktop-tablet">
              Interested in sharing our story?
            </div>
            <div className="share-out-story-small only-desktop-tablet">
              Send us an e-mail at{" "}
              <a
                href="mailto:team@neoncoat.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                team@neoncoat.com
              </a>
            </div>
          </div>
        </div>
        <div style={{ flex: "1" }} className="press-left" />
        <div className="press-image-big">
          <Img fluid={data.pressCover.childImageSharp.fluid} />
        </div>
      </div>
      <div>
        <div className="share-out-story only-mobile">
          Interested in sharing our story?
        </div>
        <div className="share-out-story-small only-mobile">
          Send us an e-mail at{" "}
          <a
            href="mailto:team@neoncoat.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            team@neoncoat.com
          </a>
        </div>
      </div>
      <div className="flex-grid">
        <div className="col" />
        <div className="press-main">
          <div className="press-seen-in">AS SEEN IN:</div>
          <div className="flex-grid press-grid">
            <div className="col press-col" style={{ marginLeft: 0 }}>
              <a
                className="press-link"
                href="https://www.insider.com/model-app-connects-professional-models-with-free-stuff-2019-4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.press1.childImageSharp.fluid}
                  alt="Neon Coat Business Insider"
                />
              </a>
            </div>
            <div className="col press-col" style={{ margin: "0px 30px" }}>
              <a
                className="press-link"
                href="https://nypost.com/2019/04/09/this-app-lets-hot-models-trade-their-looks-for-free-stuff/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.press2.childImageSharp.fluid}
                  alt="Neon Coat New York Post"
                />
              </a>
            </div>
            <div className="col press-col" style={{ marginRight: 0 }}>
              <a
                className="press-link"
                href="https://www.prnewswire.com/news-releases/neon-coat-app-empowers-modeling-community-while-elevating-brands-300651256.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.press3.childImageSharp.fluid}
                  alt="Neon Coat CISION"
                  style={{ margin: "0px 30px" }}
                />
              </a>
            </div>
          </div>
          <div className="flex-grid press-grid">
            <div className="col press-col" style={{ marginLeft: 0 }}>
              <a
                className="press-link"
                href="https://thestoryexchange.org/larissa-drekonja/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.press4.childImageSharp.fluid}
                  alt="Neon Coat Story Exchange"
                />
              </a>
            </div>
            <div className="col press-col" style={{ margin: "0px 30px" }}>
              <Img
                fluid={data.press5.childImageSharp.fluid}
                alt="Neon Coat Humans Of Fashion"
                className="press-link"
              />
            </div>
            <div className="col press-col" style={{ marginRight: 0 }}>
              <a
                className="press-link"
                href="https://guestofaguest.com/new-york/fashion-week/the-chicest-events-of-new-york-fashion-week-spring-2020?slide=5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.press6.childImageSharp.fluid}
                  alt="Neon Coat Guest of a Guest"
                  style={{ margin: "0px 30px" }}
                />
              </a>
            </div>
          </div>
          <div class="embed-container-outer">
            <div class="embed-container">
              <iframe
                src="https://www.youtube.com/embed/pajiAN9BxDo"
                frameborder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <br className="only-mobile" />
          <br className="only-mobile" />
        </div>
        <div className="col" />
      </div>
    </Layout>
  )
}

export default IndexPage
